import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import { Query, SitePageContext } from "types/gatsby-graphql"
import { MainLayout } from "@components/layouts"
import { Seo } from "@components"
import { FaTags } from "react-icons/fa"

interface IProps extends PageProps {
  pageContext: SitePageContext
  data: Query
}

const TagsTemplate: React.VFC<IProps> = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`
  return (
    <MainLayout>
      <Seo title={`บทความทั้งหมดสำหรับ "${tag}"`} />
      <div className="content">
        <h1>{tagHeader}</h1>
        <ul>
          {edges.map(({ node }) => {
            const path = node.fields?.slug!!
            const title = node.frontmatter?.title!!
            return (
              <li key={path}>
                <Link to={path}>{title}</Link>
              </li>
            )
          })}
        </ul>

        <Link to="/tags" className="is-flex is-align-items-center">
          <FaTags />
          <span className="ml-1">All tags</span>
        </Link>
      </div>
    </MainLayout>
  )
}

export default TagsTemplate

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
